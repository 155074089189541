import {ChangeDetectionStrategy, Component, computed} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';

@Component({
  selector: 'app-dine-nav',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './dine-nav.component.html',
  styleUrls: ['../../nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DineNavComponent {
  hasDineMembership = computed(() => {
    const acct = this.store.selectSignal(SessionState.getAccount);

    // If they have been flagged for the feature, or they are a free account.
    return !!acct()?.settings.hasDineClubMembership || acct()?.user.scheme_id == 19;
  });

  constructor(private store: Store) {
  }

}
