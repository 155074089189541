import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {RecordClickDirective} from 'src/app/shared/directives/record-click.directive';
import {DineContentComponent} from 'src/app/modules/dine-membership/dine-content/dine-content.component';

@Component({
  selector: 'app-dine-club',
  templateUrl: './dine-club.component.html',
  styleUrls: ['./dine-club.component.scss'],
  standalone: true,
  imports: [CommonModule, RecordClickDirective, DineContentComponent],
})
export class DineClubComponent {

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }
}
