import {Component} from '@angular/core';
import {AuthService} from 'src/app/core/services/auth.service';
import {RouterLink} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {genericErrorObserver} from '../../../observables/generic-error.observer';
import {MixpanelService} from '../../../mixpanel/mixpanel.service';
import {AccountService} from '../../../services/account.service';
import {TrustpilotService} from 'src/app/modules/trustpilot/trustpilot.service';
import {BrandService} from '../../../services/brand.service';
import {NgIf} from '@angular/common';
import {NavFastPerksComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/nav-fast-perks/nav-fast-perks.component';
import {NavCreditLadderComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/nav-credit-ladder/nav-credit-ladder.component';
import {YourFileExplainedComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/your-file-explained/your-file-explained.component';
import {
  CompleteBrokenEnrollComponent,
} from 'src/app/shared/layouts/ecce/nav/nav-items/complete-broken-enroll/complete-broken-enroll.component';
import {DownloadReportComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/download-report/download-report.component';
import {ViewPrintReportComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/view-print-report/view-print-report.component';
import {
  CreditConsultationNavComponent
} from 'src/app/shared/layouts/ecce/nav/nav-items/credit-consultation-nav/credit-consultation-nav.component';
import {DineNavComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/dine-nav/dine-nav.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    NavFastPerksComponent,
    NavCreditLadderComponent,
    YourFileExplainedComponent,
    NgIf,
    CompleteBrokenEnrollComponent,
    DownloadReportComponent,
    ViewPrintReportComponent,
    CreditConsultationNavComponent,
    DineNavComponent,
  ],
})
export class NavComponent {

  constructor(private authService: AuthService,
              private http: HttpClient,
              public accountService: AccountService,
              public trustpilotService: TrustpilotService,
  ) { }

  /*beginIntro() {
    this.loadIndicatorService.push('intro-wait');

    this.router
      .navigate(['/dashboard/home'])
      .then(() => {
        setTimeout(() => {
          this.loadIndicatorService.pop('intro-wait');
          this.howToService.run();
        }, 500);
      });
  }*/

  /**
   * Logout (and go to specific logout page)
   */
  logout() {
    MixpanelService.track('Click logout menu item');

    this.authService.logout(false, true);
  }

  isProduction(): boolean {
    return environment.production;
  }

  /**
   * Cause a 404 error.  This is strictly so we can test any interceptors, like sentry.io modals.
   */
  fakeClientError(): void {
    this.http
      .get('/missing-page')
      .subscribe(genericErrorObserver);

    throw new Error('this is a fake error');

    // Fake chunk loading error
    // throw new Error('Failed to fetch dynamically imported module: https://member.ukcreditratings.com/chunk-BD5VV6AG.js');
  }

  signPostingLink(){
    return BrandService.getSignPostingLink();
  }

}
