import {Injectable} from '@angular/core';
import {isIterable} from 'rxjs/internal/util/isIterable';
import {HttpErrorResponse} from '@angular/common/http';

/**
 * Joe and I believe this is for displaying errors inside the page, instead of in
 * floating notifications or toasts.
 *
 * NOTE: do NOT provide in root.  Each component using this in page error component should provide their own, so each page has their own
 * collection of errors.
 */
@Injectable()
export class ErrorService {

  /**
   * API Errors
   * Follows our API Error standard of error objects
   */
  public apiErrors: string[] = [];

  /**
   * General Errors
   * For example, Laravel 422 form validation is just array of fieldname/validation error
   */
  public errors = {};


  constructor() {
  }

  /**
   * Add error (typically meant for Laravel form validation style of key value)
   * @param key
   * @param value
   */
  addError(key: string, value: Array<string>) {
    this.errors[key] = value;
  }


  addApiError(error: string): this {
    this.apiErrors.push(error);

    return this;
  }

  hasErrors() {
    const fieldnames = Object.keys(this.errors);
    return fieldnames.length > 0;
  }

  /**
   * Accept an HttpErrorResponse from an API call and display any errors shown
   */
  processErrorResponse(err: HttpErrorResponse) {

    if (err.status == 422) {

      if (err.error.hasOwnProperty('errors')) {

        this.processValidationErrors(err.error.errors);

      } else {
        this.errors = err.error;
      }
    }
  }

  /**
   * Handle 422 Form Validation Errors
   */
  processValidationErrors(errors) {

    for (let fieldName in errors) {

      if (isIterable(errors[fieldName])) {
        this.addError(fieldName, errors[fieldName]);
      } else {
        this.addApiError(errors[fieldName]);
      }
    }
  }


  /**
   * Clear All Errors
   */
  clearErrors() {
    this.errors = {};
    this.apiErrors = [];
  }

}
