<div class="gc-modal-inside">
  <div class="close-button-wrap">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>

  <iframe
    id="gc-verified-iframe"
    [src]="iframeUrl()"
    (load)="backupListener($event)">
  </iframe>
</div>
