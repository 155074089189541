import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpClient, HttpContext} from '@angular/common/http';
import {interval, Subscription, switchMap, takeWhile} from 'rxjs';
import {HTTP_LOADER} from "../../../../core/interceptor/loadindicator.interceptor";

/**
 * Manages displaying a modal for customers to run through GoCardless Verified Mandates flow.  This modal manages 3 methods to detect the
 * process is finished:
 * 1. listenForChallengeComplete(): upon completion, GoCardless redirects the iframe back to our /gocardless-return.   That page posts a
 *    message which will be picked up here.
 * 2. backupListener(): listen to the (load) event on the iframe.  Detect if the URL of the page is /gocardless-return.  This will only
 *    work if the same origin policy of the iframe + device allows it.
 * 3. startTertiaryPolling(): poll GoCardless through their Billing Request API to detect the step is completed.
 */

@Component({
  selector: 'app-gocardless-verified-mandate-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './gocardless-verified-mandate-modal.component.html',
  styleUrl: './gocardless-verified-mandate-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GocardlessVerifiedMandateModalComponent implements OnDestroy {
  @HostBinding('style.display') hostDisplay: string = 'none';

  /**
   * Fired when the iframe is redirected back to our /gocardless-return URL.
   */
  @Output() completed = new EventEmitter<boolean>();

  iframeUrl: WritableSignal<SafeResourceUrl>;
  private tertiaryPollingSub: Subscription | null = null;

  get isShowModal(): boolean {
    return this._isShowModal;
  }

  set isShowModal(value: boolean) {
    this._isShowModal = value;

    if (this._isShowModal) {
      // Show the body of this component
      this.hostDisplay = 'block';

      // Prevent page scrolling while the modal is visible.  This will prevent "floating" dropdowns and other render issues in
      // the position:fixed modal
      document.body.style.overflow = 'hidden';

      this.startTertiaryPolling();
    } else {
      this.hostDisplay = 'none';
      document.body.style.overflow = 'initial';
    }

    // We are OnPush change detection.  Need to manually detect changes.
    this.cdr.markForCheck();
  }

  private _isShowModal: boolean = false;
  /**
   * Reference to the callback function supplied to listenForChallengeComplete.  This is kept so
   * we can safely remove it later.
   */
  private lastChallengeCallbackFunction!: (event: MessageEvent) => void;

  constructor(
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) {
    this.listenForChallengeComplete();

    this.iframeUrl = signal(sanitizer.bypassSecurityTrustResourceUrl(''));
  }

  /**
   * Open modal, setting iframe to a new Auth URL.
   * @param authUrl URL to open in the iframe, which GoCardless provided in Billing Request Flow
   */
  open(authUrl: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(authUrl);

    if (!safeUrl) {
      throw `Unable to load challenge URL: ${authUrl}`;
    }

    this.iframeUrl.set(safeUrl);
    this.isShowModal = true;
  }

  /**
   * The X button in the top right of the modal
   */
  closeModal(): void {
    this.isShowModal = false;

    this.completed.next(false);
  }

  /**
   * When the bank's challenge process is complete, they redirect the form over to our page.  This page
   * uses window.postMessage() to announce that it has been reached.  This function sets up a callback
   * for that postMessage.
   */
  private listenForChallengeComplete(): void {
    this.removePreviousListener();

    this.lastChallengeCallbackFunction = (event: MessageEvent) => {
      // This is the domain that is specified in the challenge_url variable on the Acquired post
      if (event.origin !== window.origin) {
        return;
      }

      // The only acceptable data.  This is specified in GoCardlessVerifiedMandateReturnController
      if (event.data === 'gocardless challenge success') {
        this.notifySuccess();
      } else if (event.data === 'gocardless challenge exited') {
        this.notifyFailure();
      }
    };

    // Listen for events from our 3ds-iframe-result page
    window.addEventListener('message', this.lastChallengeCallbackFunction);
  }

  private removePreviousListener() {
    if (this.lastChallengeCallbackFunction) {
      window.removeEventListener('message', this.lastChallengeCallbackFunction);
    }
  }

  private notifySuccess(): void {
    console.debug('GoCardless iframe challenge complete');

    // Let main form know we are done and succeeded.
    this.completed.next(true);

    // Hide ourselves
    this.isShowModal = false;

    this.cdr.markForCheck();
  }

  private notifyFailure(): void {
    console.debug('GoCardless iframe challenge exited');

    // Let main form know we are done and failed.
    this.completed.next(false);

    // Hide ourselves
    this.isShowModal = false;

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.removePreviousListener();
    this.tertiaryPollingSub?.unsubscribe();
  }

  /**
   * Listens for onload events from the iframe.  There have been some customers who get stuck on this step.  Hopefully this will help them.
   */
  backupListener(event: Event): void {
    if (!event.target) {
      return;
    }

    const eventTarget = event.target as HTMLIFrameElement;

    // This method's processing is delayed to let listenForChallengeComplete() do its job first.  This should prevent this.completed
    // EventEmitter from firing twice.
    const delay = 1000;

    // This is only available if iframe is the same origin
    if (eventTarget.contentDocument?.location.href.includes('gocardless-return?success=1')) {
      setTimeout(() => {
        // If the modal is already hidden, primary event detection worked or the process was abandoned somehow.
        if (!this.isShowModal) {
          return;
        }

        this.notifySuccess();
      }, delay);
    } else if (eventTarget.contentDocument?.location.href.includes('gocardless-return?success=0')) {
      setTimeout(() => {
        // If the modal is already hidden, primary event detection worked or the process was abandoned somehow.
        if (!this.isShowModal) {
          return;
        }

        this.notifyFailure();
      }, delay);
    }
  }

  /**
   * Another backup plan to detect process completion.  This polls the server, which polls GoCardless to see if the challenge step in the
   * Billing Request is complete.  Stops when completion is detected, or this modal is hidden.
   */
  startTertiaryPolling(): void {
    this.tertiaryPollingSub?.unsubscribe();

    this.tertiaryPollingSub = interval(2000)
      .pipe(
        switchMap(() => this.http.get<PollingResponse>('/api/gocardless-challenge-poll', {context: new HttpContext().set(HTTP_LOADER, false)})),
        takeWhile(response => !response.completed && this.isShowModal, true),
      )
      .subscribe({
        next: resp => {
          if (resp.completed) {
            this.notifySuccess();
          }
        },
        error: err => console.error(err),
      });
  }
}

interface PollingResponse {
  completed: boolean;
}
