<!-- Errors -->
<div *ngIf="errorService.errors">
  <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errorService.errors)">
    {{errorService.errors[key]}}
  </div>
</div>

@for (error of errorService.apiErrors; track error) {
<div class="alert alert-danger" role="alert">
  {{error}}
</div>
}

<!--<pre>{{errorService.errors | json}}</pre>-->
<!--<pre>{{errorService.apiErrors | json}}</pre>-->
