import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';

@Component({
  selector: 'app-credit-consultation-nav',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './credit-consultation-nav.component.html',
  styleUrls: ['../../nav.component.scss'],
})
export class CreditConsultationNavComponent {

  constructor(private consultModalService: CreditConsultationIaoModalService) {
  }

  showConsultationModal(): void {
    this.consultModalService.run().then(
      () => console.log('Credit consult modal done'),
      () => console.log('Credit consult modal abandoned'),
    );
  }

}
