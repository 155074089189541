<app-error></app-error>

<form (ngSubmit)="save()" [formGroup]="signupForm">

  <div class="mb-3 form-floating">
    <input
      aria-label="Sort Code"
      type="text"
      class="form-control position-static"
      formControlName="sortCode"
      placeholder=""
      id="sortCode"
      [ngClass]="{ 'is-invalid': isSubmitted && formControls.sortCode.errors }">
    <label for="sortCode">Sort Code</label>

    <div class="invalid-feedback" *ngIf="validate('sortCode', 'required')">Required</div>
    <div class="invalid-feedback" *ngIf="validate('sortCode', 'pattern')">Must be a number</div>
    <div class="invalid-feedback" *ngIf="validate('sortCode', 'minlength') || validate('sortCode', 'maxlength')">Must be 6 digits</div>
  </div>

  <div class="mb-3 form-floating">
    <input
      aria-label="Sort Code"
      type="text"
      class="form-control position-static"
      formControlName="verifySortCode"
      placeholder=""
      id="verifySortCode"
      [ngClass]="{ 'is-invalid': isSubmitted && formControls.verifySortCode.errors }">
    <label for="verifySortCode" class="form-label">Verify Sort Code</label>

    <div class="invalid-feedback" *ngIf="validate('verifySortCode', 'required')">Required</div>
    <div class="invalid-feedback" *ngIf="validate('verifySortCode', 'sortcodematch')">Sort Codes do not match</div>
  </div>

  <div class="mb-3 form-floating">
    <input
      type="text"
      class="form-control position-static"
      formControlName="accountNumber"
      id="accountNumber"
      placeholder=""
      [ngClass]="{ 'is-invalid': isSubmitted && formControls.accountNumber.errors }">
    <label for="accountNumber" class="form-label">Account Number</label>

    <div class="invalid-feedback" *ngIf="validate('accountNumber', 'pattern')">Must be a number</div>
    <div class="invalid-feedback" *ngIf="validate('accountNumber', 'required')">Required</div>
  </div>

  <div class="mb-3 form-floating">
    <input
      type="text"
      class="form-control position-static"
      formControlName="verifyAccountNumber"
      id="verifyAccountNumber"
      placeholder=""
      [ngClass]="{ 'is-invalid': isSubmitted && formControls.verifyAccountNumber.errors }">
    <label for="verifyAccountNumber" class="form-label">Verify Account Number</label>

    <div class="invalid-feedback" *ngIf="validate('verifyAccountNumber', 'required')">Required</div>
    <div class="invalid-feedback" *ngIf="validate('verifyAccountNumber', 'accountnumbermatch')">Account numbers do not match</div>
  </div>

  <div class="butts my-4">
    <button class="btn btn-primary btn-lg" type="submit">
      Submit &amp; Save
    </button>

    <button class="btn btn-secondary btn-lg" (click)="close()">
      No Thanks
    </button>
  </div>
</form>

<app-gocardless-verified-mandate-modal (completed)="gocardlessVerifiedMandateCompleted($event)" />
