import {Component} from '@angular/core';
import {ErrorService} from './error.service';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class ErrorComponent {

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;

  // Note: ErrorService is used directly by the template
  constructor(public errorService: ErrorService) { }
}
