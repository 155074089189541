import {Component} from '@angular/core';
import {AccountService} from 'src/app/shared/services/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {GoCardlessService} from 'src/app/modules/iao-chain/go-cardless/go-cardless.service';
import {BillingScheme} from 'src/app/shared/models/billing-scheme.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  DirectDebitSubscriptionFormComponent,
} from 'src/app/modules/card-services/components/direct-debit-subscription-form/direct-debit-subscription-form.component';

@Component({
  selector: 'app-go-cardless',
  templateUrl: './go-cardless.component.html',
  styleUrls: ['./go-cardless.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DirectDebitSubscriptionFormComponent,
  ],
})
export class GoCardlessComponent {

  public isSubmitted = false;
  billingScheme: BillingScheme;
  currentPrice$: Observable<string>;

  constructor(
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private gocardlessService: GoCardlessService,
  ) {
    this.currentPrice$ = this.accountService
      .getAccount()
      .pipe(map(acct => acct.user.recurring_price));

    this.gocardlessService
      .getDiscountedBillingScheme()
      .subscribe({
        next: scheme => this.billingScheme = scheme,
        error: e => console.error(e),
      });
  }

  /**
   * The form finished and the GoCardless sign up happened
   */
  private yes(): void {
    this.accountService
      .recordClickLog('Direct Debit', 'Yes')
      .subscribe(genericErrorObserver);

    this.activeModal.close();
  }

  /**
   * The customer clicked skip
   */
  private no(): void {
    this.accountService
      .recordClickLog('Direct Debit', 'No')
      .subscribe(genericErrorObserver);

    this.activeModal.close();
  }

  /**
   * Output from GoCardless form component
   */
  formFinished(result: boolean): void {
    if (result) {
      this.yes();
    } else {
      this.no();
    }
  }
}
