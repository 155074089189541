<!-- NOT QUALIFIED MESSAGE TEMPLATE -->
<ng-template #cm_not_qualified_view>
  <h5 class="my-5 w-75 text-center mx-auto text-center">
    Unfortunately, we are not able to match you with a car loan. You may call us if you have any additional
    questions we can help with, you'll find our UK customer service team friendly and helpful. Phone: 0161 250 7700
  </h5>

  <div class="w-50 mx-auto rtn-btn-cont">
    <a class="btn btn-lg btn-block btn-primary" [routerLink]="['/dashboard/home']">Return to Dashboard</a>
  </div>
</ng-template>


<!-- ACCEPTED MESSAGE TEMPLATE -->
<ng-template #cm_accepted_view>
  <h5 class="my-5 w-75 mx-auto text-center">
    Your Application Is Looking Good!<br/>
    Hold tight, our Partner is processing your application and will be in touch shortly with confirmation of your acceptance.
  </h5>

  <div class="w-50 mx-auto rtn-btn-cont">
    <a class="btn btn-lg btn-block btn-primary" [routerLink]="['/dashboard/home']">Return to Dashboard</a>
  </div>
</ng-template>


<!-- ERROR MESSAGE -->
<ng-template #cm_app_error>
  <h5 class="my-5 w-75 text-center mx-auto text-center">
    We're Sorry.  An error occurred while transmitting your application to CarMoney. You may call us if you have any additional
    questions we can help with, you'll find our UK customer service team friendly and helpful. Phone: 0161 250 7700
  </h5>

  <div class="w-50 mx-auto rtn-btn-cont">
    <a class="btn btn-lg btn-block btn-primary" [routerLink]="['/dashboard/home']">Return to Dashboard</a>
  </div>
</ng-template>

<!-- FORM TEMPLATE -->
<ng-template #cm_form_view>
  <div class="modal-body">
<!--    <pre>{{errors | json}}</pre>-->
    <div class="px-2">
      <form (ngSubmit)="save()" [formGroup]="carMoneyForm" class="form-horizontal" #form="ngForm" id="car-money-offer-form">

        <div class="form-group">
          <div class="form-row">
            <div class="col-6">

              <!-- AmountToBorrow -->
              <div class="form-group">
                <label for="amountToBorrow" class="">Amount to Borrow</label>
                <div class="input-group input-group-sm mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">&pound;</div>
                  </div>
                  <input
                    aria-label="Amount to Borrow"
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="amountToBorrow"
                    id="amountToBorrow"
                    placeholder="Amount to Borrow"
                    [ngClass]="{ 'is-invalid': isSubmitted && formControls.amountToBorrow.errors || errors.hasOwnProperty('AmountToBorrow')}">
                  <div class="invalid-feedback" *ngIf="hasError('amountToBorrow', 'required')">Required</div>
                  <div class="invalid-feedback" *ngIf="hasError('amountToBorrow', 'pattern')">Must be a number</div>
                  <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('AmountToBorrow')">{{errors.AmountToBorrow}}</div>
                </div>
              </div>
            </div>

          <div class="col-6">
              <!-- Vehicle Type -->
              <div class="form-group">
                <label for="vehicleType" class="">Vehicle Type</label>
                <select id="vehicleType" class="form-control form-control-sm" formControlName="vehicleType"
                        [ngClass]="{ 'is-invalid': errors.hasOwnProperty('vehicleType') || (isSubmitted && formControls.vehicleType.errors) }">
                  <option *ngFor="let vehicleType of vehicleTypes; let i = index" [ngValue]="vehicleType">
                    {{vehicleType}}
                  </option>
                </select>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('VehicleType')">{{errors?.VehicleType}}</div>
                <div class="invalid-feedback" *ngIf="formControls.vehicleType && formControls.vehicleType.touched && formControls.vehicleType.hasError('required')">Required</div>
                <div class="invalid-feedback" *ngIf="formControls.vehicleType && formControls.vehicleType.touched && formControls.vehicleType.hasError('pattern')">Invalid Phone</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Applicant -->
        <div class="form-group">
          <div class="form-row">
            <div class="col-12">
              <p class="mt-2">Applicant</p>
            </div>
          </div>

          <div class="form-row">
            <div class="col-3">
              <!-- Forename -->
              <div class="form-group">
                <label for="forename" class="">First Name</label>
                <input
                  aria-label="Forename"
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="forename"
                  id="forename"
                  placeholder="Forename"
                  [ngClass]="{ 'is-invalid': isSubmitted && formControls.forename.errors || errors.hasOwnProperty('Forename') }">
                <div class="invalid-feedback" *ngIf="hasError('forename', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Forename')">{{errors.Forename}}</div>
              </div>
            </div>

            <div class="col-3">
              <!-- Surname -->
              <div class="form-group">
                <label for="surname" class="">Last Name</label>
                <input
                  aria-label="Surname"
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="surname"
                  id="surname"
                  placeholder="Surname"
                  [ngClass]="{ 'is-invalid': isSubmitted && formControls.surname.errors || errors.hasOwnProperty('Surname') }">
                <div class="invalid-feedback" *ngIf="hasError('surname', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Surname')">{{errors.Surname}}</div>
              </div>
            </div>

            <div class="col-3">
              <!-- Email -->
              <div class="form-group">
                <label for="email" class="">Email</label>
                <input
                  aria-label="Email"
                  type="email"
                  class="form-control form-control-sm"
                  formControlName="email"
                  id="email"
                  placeholder="Email"
                  [ngClass]="{ 'is-invalid': isSubmitted && formControls.email.errors || errors.hasOwnProperty('Email') }">
                <div class="invalid-feedback" *ngIf="hasError('email', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Email')">{{errors.Email}}</div>
              </div>
            </div>

            <div class="col-3">
              <!-- Mobile -->
              <div class="form-group">
                <label for="mobile" class="">Phone</label>
                <input
                  aria-label="Mobile Phone"
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="mobile"
                  id="mobile"
                  placeholder="Mobile Phone"
                  [ngClass]="{ 'is-invalid': isSubmitted && formControls.mobile.errors || errors.hasOwnProperty('Mobile') }">
                <div class="invalid-feedback" *ngIf="hasError('mobile', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Mobile')">{{errors.Mobile}}</div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <!-- Driving licence Type -->
              <div class="form-group">
                <label for="drivingLicenseType" class="">Driving License Type</label>
                <select id="drivingLicenseType" class="form-control form-control-sm" formControlName="drivingLicenseType"
                        [ngClass]="{ 'is-invalid': errors.hasOwnProperty('DrivingLicenseType') || (isSubmitted && formControls.drivingLicenseType.errors) }">
                  <option *ngFor="let drivingLicenseType of drivingLicenseTypes; let i = index" [ngValue]="drivingLicenseType">
                    {{drivingLicenseType}}
                  </option>
                </select>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('DrivingLicenseType')">{{errors?.DrivingLicenseType}}</div>
                <div class="invalid-feedback" *ngIf="formControls.drivingLicenseType && formControls.drivingLicenseType.touched && formControls.drivingLicenseType.hasError('required')">Required</div>
              </div>
            </div>

            <div class="col-6">
              <!-- Martial status -->
              <div class="form-group">
                <label for="maritalStatus" class="">Marital Status</label>
                <select id="maritalStatus" class="form-control form-control-sm" formControlName="maritalStatus"
                        [ngClass]="{ 'is-invalid': errors.hasOwnProperty('MaritalStatus') || (isSubmitted && formControls.maritalStatus.errors) }">
                  <option *ngFor="let maritalStatus of maritalStatuses; let i = index" [ngValue]="maritalStatus">
                    {{maritalStatus}}
                  </option>
                </select>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('MaritalStatus')">{{errors?.MaritalStatus}}</div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <!-- UK Passport Holder (yes/no) -->
              <div class="form-group">
                <div class="">
                  <label class="d-block">Hold a valid UK Passport?</label>
                  <div class="form-check">
                    <label class="form-check-label" for="validUkPassportYes">
                      <input class="form-check-input" type="radio" name="validUkPassport" id="validUkPassportYes" value="yes" checked>
                      <span>Yes</span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label" for="validUkPassportNo">
                      <input class="form-check-input" type="radio" name="validUkPassport" id="validUkPassportNo" value="no" checked>
                      <span>No</span>
                    </label>
                  </div>
                  <div class="invalid-feedback" *ngIf="formControls.validUkPassport && formControls.validUkPassport.touched && formControls.validUkPassport.hasError('required')">Required</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Address subform -->

        <div class="form-group">
          <div class="form-row">
            <div class="col-12">
              <p class="mt-3">Address <span class="small">(please provide 3 years of address history)</span></p>
            </div>
          </div>
        </div>

        <app-address-form [(formGroup)]="carMoneyForm" [errors]="errors" [account]="account" [isSubmitted]="isSubmitted"></app-address-form>

        <!-- Employment subform -->
        <div class="form-group">
          <div class="form-row">
            <div class="col-12">
              <p class="mt-2">Employment <span class="small">(please provide 3 years of employment history)</span></p>
            </div>
          </div>
        </div>

        <app-employment-form [(formGroup)]="carMoneyForm" [errors]="errors" [isSubmitted]="isSubmitted"></app-employment-form>


        <div class="form-group">
          <div class="form-row">
            <div class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <p class="small text-center">By clicking Submit & Save, you understand and are happy with UK Credit Ratings to share your information
                with our auto finance partner, Car Money.</p>

              <button type="submit" class="btn btn-block btn-lg btn-primary mt-5">Submit & Save</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</ng-template>


<!-- Main View -->
<div class="detail-page">
  <div class="container">
    <div class="backbar">
      <button class="btn btn-primary  btn-arrow-left" appBackButton>Back</button>
    </div>

    <div class="py-2 text-center logo-cont">
      <div class="logo mx-auto">
        <img class="my-3 justify-content-center align-self-center" src='/assets/images/cta/LGO_CarMoney.svg' />
        <p class="logo-text">The car finance ninjas</p>
      </div>
    </div>

    <div class="form-container">
      <div [ngTemplateOutlet]="templateName"></div>
    </div>
  </div>
</div>

