import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from 'src/app/core/core.module';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DirectDebitRequest} from 'src/app/modules/card-services/models/direct-debit-request';
import {AccountService} from 'src/app/shared/services/account.service';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {ProcessorResponse} from 'src/app/modules/card-services/models/processor-response.enum';
import {ErrorService} from 'src/app/core/components/error/error.service';
import {ErrorComponent} from 'src/app/core/components/error/error.component';
import {
  GocardlessVerifiedMandateModalComponent,
} from 'src/app/modules/card-services/components/gocardless-verified-mandate-modal/gocardless-verified-mandate-modal.component';

@Component({
  selector: 'app-direct-debit-subscription-form',
  standalone: true,
  imports: [CommonModule, CoreModule, ReactiveFormsModule, ErrorComponent, GocardlessVerifiedMandateModalComponent],
  providers: [ErrorService],
  templateUrl: './direct-debit-subscription-form.component.html',
  styleUrls: ['./direct-debit-subscription-form.component.scss'],
})
export class DirectDebitSubscriptionFormComponent {
  @ViewChild(GocardlessVerifiedMandateModalComponent) private gocardlessModal!: GocardlessVerifiedMandateModalComponent;

  signupForm = new FormGroup<DirectDebitForm>({
    sortCode: new FormControl<string>('', {
      nonNullable: true, validators: [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6),
      ],
    }),
    verifySortCode: new FormControl<string>('', {
      nonNullable: true, validators: [
        Validators.required,
        this.checkSortCodesMatch('sortcodematch'),
      ],
    }),
    accountNumber: new FormControl<string>('', {
      nonNullable: true, validators: [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ],
    }),
    verifyAccountNumber: new FormControl<string>('', {
      nonNullable: true, validators: [
        Validators.required,
        this.checkAccountNumbersMatch('accountnumbermatch'),
      ],
    }),
  });

  isSubmitted = false;
  // Once a button is pushed and we finished.  True if we signed up, false if we abandoned
  @Output() finished = new EventEmitter<boolean>();

  constructor(
    private accountService: AccountService,
    private notificationService: ToastService,
    private errorService: ErrorService,
  ) { }

  save() {
    this.errorService.clearErrors();
    this.isSubmitted = true;

    if (!this.signupForm.dirty || this.signupForm.invalid) {
      return;
    }

    MixpanelService.track('Submit direct debit sign up form');

    const request: DirectDebitRequest = this.signupForm.getRawValue();

    this.accountService.signupDirectDebit(request)
      .subscribe({
        next: resp => {
          MixpanelService.track('SuccessfulSubmit direct debit sign up form', {result: resp.result});

          // If the billing scheme was updated since they are already on direct debit, show success.
          if (!!resp?.message) {
            this.notificationService.success('Billing request received.  It will take 3-5 business days to confirm the change.');
            this.finished.emit(true);
            return;
          }

          // If a provider was hit, act on the response.
          switch (resp?.result) {
            case ProcessorResponse.SUCCESS:
              this.notificationService.success('It will take 3-5 business days to confirm the change.');
              this.finished.emit(true);
              break;

            case ProcessorResponse.FAILED:
              this.errorService.addApiError(resp.error)
              break;

            case ProcessorResponse.WANTS_3DS_CHALLENGE:
              this.gocardlessModal.open(resp.challengeUrl);
              break;
          }
        },
        error: error => {
          this.errorService.processErrorResponse(error);
        },
      });
  }

  validate(fieldName: string, errorName: string): boolean {
    return (this.formControls
      && this.formControls[fieldName].touched
      && this.formControls[fieldName].hasError(errorName));
  }

  checkSortCodesMatch(errorType: string) {
    return (input: FormControl<string>) => {
      if (!input.root || !input.root.get('verifySortCode')) {
        return null;
      }
      const isMatch = (input.root.get('sortCode').value === input.value);
      return isMatch ? null : { [errorType]: true };
    };
  }


  checkAccountNumbersMatch(errorType: string) {
    return (input: FormControl<string>) => {
      if (!input.root || !input.root.get('verifyAccountNumber')) {
        return null;
      }
      const isMatch = (input.root.get('accountNumber').value === input.value);
      return isMatch ? null : { [errorType]: true };
    };
  }

  get formControls(): DirectDebitForm {
    return this.signupForm.controls;
  }

  handleValidationErrors(err) {
    if (err.status === 422) {
      Object.keys(err.error.errors).map(key => {
        this.notificationService.error(err.error.errors[key]);
      });
    } else {
      this.notificationService.error(err.error.message);
    }
  }

  close() {
    this.finished.emit(false);
  }

  gocardlessVerifiedMandateCompleted(event: boolean) {
    if (event) {
      MixpanelService.track('3DS Success', {processor_id: 19, direct_debit: true});
      this.notificationService.success('It will take 3-5 business days to confirm the change.');
      this.finished.emit(true);
    } else {
      MixpanelService.track('3DS Fail', {processor_id: 19, direct_debit: true});
      this.errorService.addApiError('Direct debit verification has failed to complete');
    }
  }
}

interface DirectDebitForm {
  sortCode: FormControl<string>;
  verifySortCode: FormControl<string>;
  accountNumber: FormControl<string>;
  verifyAccountNumber: FormControl<string>;
}
