<div class="go-cardless px-3 py-5">
  <div class="text-center">
    <h1 class="">Save 25%</h1>

    <h4>Pay <span class="cross">&pound;{{ currentPrice$ | async }}</span>
      <span class="new-price"> &pound;{{ billingScheme?.recurring_price }}</span> when you sign up for Direct Debit</h4>
<!--    <img class="mb-4" src="assets/images/Subline.svg" alt="Pay less when you sign up for Direct Debit"/>-->
  </div>

  <app-direct-debit-subscription-form (finished)="formFinished($event)"></app-direct-debit-subscription-form>

  <p class="small fw-light text-center disclaimer">After your free trial, any continued subscription charge will be reduced to
    &pound;{{ billingScheme?.recurring_price }}</p>
</div>
